<template>
<apexchart v-bind="config"/>
</template>

<script>
export default {
    name: 'chart',
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    foreignObject {
        height: 100%;
    }
    .apexcharts-tooltip {
        // text-shadow: 0 0 3px black;
        --shadow-color: rgba(0, 0, 0, 0.3);
        text-shadow:
            0.1em 0 var(--shadow-color),
            0 0.1em var(--shadow-color),
            -0.1em 0 var(--shadow-color),
            0 -0.1em var(--shadow-color),
            -0.1em -0.1em var(--shadow-color),
            -0.1em 0.1em var(--shadow-color),
            0.1em -0.1em var(--shadow-color),
            0.1em 0.1em var(--shadow-color);
    }
    .apexcharts-legend-series {
        display: inline-flex;
        align-items: center;
        gap: 12px;
    }
    .apexcharts-legend-marker {
        margin-right: 0;
    }
    .apexcharts-datalabels {
        transform: translateY(7px);
        text {
            fill: #282828;
        }
    }
}
</style>
